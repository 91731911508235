import React from 'react';

function Offering({ offering, onClose }) {
    return (
        <div className="bg-white p-6 gap-2 w-full">
            <button onClick={onClose} className="absolute top-0 right-0 mt-4 mr-4 text-3xl">×</button>
            
            <div className='flex py-2 px-2 max-w-max space-y-2'>
              <img src={offering.icon} alt={offering.title} className="block self-center mr-2 w-32 h-32 rounded-full mx-auto" />
              <div className="space-y-2 sm:text-left space-y-0.5">
                <h2 className="text-xl font-bold mt-4">{offering.title}</h2>
                <p className="mt-2 text-md text-slate-500 font-normal">{offering.summary}</p>
              </div>
            </div>
            
            <div className="text-justify">
              <p className="mt-4" style={{ whiteSpace: 'pre-wrap' }}>{offering.story}</p>
              <h3 className="text-xl mt-8">How can I serve you?</h3>
              <p className="mt-4">{offering.case}</p>
            </div>
            
            {offering.files && (
                <div className="grid mt-4 p-6 grid-cols-2 gap-3">
                    {offering.files.resume && <button className="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded p-6" onClick={() => window.open(offering.files.resume, '_blank')}>PDF Resume</button>}
                    {offering.files.resumetxt && <button className="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded" onClick={() => window.open(offering.files.resumetxt, '_blank')}>Text Resume</button>}
                    {offering.files.fourWeeksWith && <button className="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded p-6" onClick={() => window.open(offering.files.fourWeeksWith, '_blank')}><em>Four Weeks With</em></button>}
                    {offering.files.codeRoom && <button className="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded" onClick={() => window.open(offering.files.codeRoom, '_blank')}>Code Room</button>}
                    {offering.files.prayGPT && <button className="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded p-6" onClick={() => window.open(offering.files.prayGPT, '_blank')}>Prayer Companion</button>}
                    {offering.files.claimSamaritan && <button className="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded" onClick={() => window.open(offering.files.claimSamaritan, '_blank')}>Claim Samaritan</button>}
                </div>
            )}
            <div className="w-full flex justify-center items-center mt-8">
              <button 
                className="px-4 py-1 text-2xl text-teal-600 font-semibold rounded-full border border-teal-200 hover:text-white hover:bg-teal-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-teal-600 focus:ring-offset-2" 
                onClick={() => window.open('https://calendly.com/dalmomendonca/consulting', '_blank')}>
                  Schedule a Free Consultation
              </button>         
            </div>
        </div>
    ); 
}

export default Offering;
